import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    Button,
    FormGroup,
    Form,
    Modal,
    Spinner,
    Row,
    Col
} from "reactstrap";
import { Select } from 'antd';
import { createNotifyContent } from "api/notifyContent";
import { updateNotifyContent } from "api/notifyContent";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DataTypeNotify from "data/typeNotify";

const ModalNotify = ({
    title,
    dataUpdate,
    isOpenModal,
    handleCancelModal,
    handleGetAllNotify
}) => {
    const [open, setOpen] = useState(isOpenModal);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        type: null,
        content: "",
    });
    //logic
    const onFinish = async () => {
        if (loading) return;
        if (!formData.content || !formData.type) {
            toast.error("Các trường không được để trống");
            return;
        }
        setLoading(true);
        if (Object.keys(dataUpdate)?.length) {
            const res = await updateNotifyContent(dataUpdate?._id, formData);
            if (res.success) {
                handleGetAllNotify()
                toast.success(`Cập nhập thành công`);
                setFormData({
                    type: "",
                    content: "",
                })
            }
        } else {
            const res = await createNotifyContent(formData);
            if (res.success) {
                handleGetAllNotify()
                toast.success(`Tạo thành công`);
                setFormData({
                    type: "",
                    content: "",
                })
            } else {
                toast.error(res.message);
            }
        }
        handleCancelModal(false);
        setOpen(false);
        setLoading(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectType = (e) => {
        formData.type = e;
    }
    console.log(dataUpdate);
    useEffect(() => {
        setFormData({
            type: dataUpdate?.type,
            content: dataUpdate?.content,
        })
    }, [dataUpdate])

    useEffect(() => {
        setOpen(isOpenModal);
    }, [isOpenModal]);
    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={open}
                toggle={() => handleCancelModal(false)}
                style={{ maxWidth: 700 }}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {title}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => handleCancelModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="content"
                                    >
                                        Loại nội dung thôn
                                    </label>
                                    <Select
                                        style={{ width: "100%" }}
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={(e) => handleSelectType(e)}
                                        value={formData.type}
                                        options={DataTypeNotify}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="content"
                            >
                                Nội dung
                            </label>
                            <CKEditor
                                editor={Editor}
                                plugins={["WordCount"]}
                                data={dataUpdate?.content ? dataUpdate.content : formData.content}
                                config={{ height: 500 }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    formData.content = data;
                                }}
                            />
                        </FormGroup>
                    </Form>
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onChange={handleChange}
                    >
                        Hủy bỏ
                    </Button>
                    <Button color="primary" type="button" onClick={onFinish}>
                        {loading ? <Spinner /> : title}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ModalNotify;