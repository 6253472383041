import { useEffect, useRef, useState } from "react";
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Button,
    Col,
    CardFooter,
    PaginationItem,
    PaginationLink,
    Pagination,
} from "reactstrap";
import LoadingSpin from "react-loading-spin";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { BsTrashFill } from "react-icons/bs";
import { BiSolidEdit } from "react-icons/bi";
import { Modal } from "antd";
import { toast } from "react-toastify";
import ModalNotify from "./modelNotify/modelNotify";
import { getAllNotifyContent } from "api/notifyContent";
import { deleteNotifyContent } from "api/notifyContent";
import { removeTagHtml } from "function/removeTagHtml";
import DataTypeNotify from "data/typeNotify";
import SimpleHeader from "components/Headers/SimpleHeader.js";

function getData(arr, totalPage, pageIndex, pageSize = 10) {
    if (pageIndex < 1) {
        return;
    }
    const startIndex = (pageIndex - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalPage * pageSize);
    const currentPageData = arr.slice(startIndex, endIndex);
    return currentPageData;
}

const ManagementNotify = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isFilter, setIsFilter] = useState(true);

    const [title, setTitle] = useState("");
    const search = useRef("")

    const [pageIndex, setPageIndex] = useState(1);

    const [dataUpdate, setDataUpdate] = useState({})

    const [dataNotifyList, setDataNotifyList] = useState([]);
    const [dataSort, setDataSort] = useState([])
    const [dataSearch, setDataSearch] = useState([]);
    const [totalPage, setTotalPage] = useState([])

    const handleAddOrderEntity = () => {
        setIsOpenModal(true)
        setTitle("Thêm bài test order entity")
        setDataUpdate({})
    }

    const handleDragEnd = (e) => {
    }

    const handleGetAllNotify = async () => {
        const res = await getAllNotifyContent();
        const sort = res.data.map(item => { return (item._id) });
        const data = res.data.sort((a, b) => sort.indexOf(a["_id"]) - sort.indexOf(b['_id']));
        setDataSort(sort)
        setDataNotifyList(data);
        setLoading(false);
    };

    const handleEditNotify = (item) => {
        setTitle("Sửa bài test order entity")
        setDataUpdate(item);
        setIsOpenModal(true)
    }

    //search test entity
    const handleSearch = () => {
        let data = dataNotifyList;
        const s = search.current
        if (s) {
            data = data.filter(item => {
                if (item.title.search(s) !== -1) {
                    return true;
                }
                if (item.title.search(s) !== -1) {
                    return true;
                }
            })
        }
        setDataSearch(getData(data, totalPage, pageIndex, 10))
    }

    const handleCancelModal = () => {
        setIsOpenModal(false);
    };

    const renderPaginationItemDivs = (totalPage, pageIndex, setPageIndex) => {
        const divs = [];
        for (let i = 1; i <= totalPage; i++) {
            divs.push(
                <PaginationItem className={pageIndex == i && "active"}>
                    <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                            e.preventDefault();
                            setPageIndex(i);
                        }}
                    >
                        {i} <span className="sr-only">(current)</span>
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return divs;
    };

    const handleDelete = () => {
        if (isOpen) {
            fetchApideletePostById(isOpen);
            setIsOpen(false);
        }
    };
    const fetchApideletePostById = async (id) => {
        try {
            setLoading(true);
            const res = await deleteNotifyContent(id);
            if (res?.success) {
                toast.success(`Xóa thành công`);
            } else {
                toast.error(`Lỗi xóa thông báo! Vui lòng thử lại.`);
            }
            handleGetAllNotify();
        } catch (error) {
            console.log("error:", error);
        } finally {
            setLoading(false);
        }
    };

    const Filter = () => {
        return (
            <></>
        );
    };

    useEffect(() => {
        setTotalPage(Math.ceil(dataNotifyList.length / 10))
    }, [dataNotifyList])

    useEffect(() => {
        handleSearch()
    }, [dataNotifyList, pageIndex, totalPage])

    useEffect(() => {
        handleGetAllNotify();
    }, []);

    return (
        <>
            <SimpleHeader
                name="Tables"
                parentName="Tables"
                setIsFilter={() => setIsFilter(!isFilter)}
                filter={isFilter}
                props={<Filter />}
            />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <Row>
                                    <Col className="col-6">
                                        <h3 className="mb-0">Danh sách nội dung thông báo</h3>
                                    </Col>
                                    <Col className="text-right col-6 px-0">
                                        <Button onClick={handleAddOrderEntity}>Thêm mới</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <div
                                style={{
                                    display: loading ? "block" : "none",
                                    textAlign: "center",
                                }}
                            >
                                <div className={"ExampleOfUsage "}>
                                    <LoadingSpin />
                                </div>
                            </div>
                            <div style={{ display: loading ? "none" : "block" }}>
                                {dataNotifyList?.length ? (
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Table
                                            className="align-items-center table-flush"
                                            responsive
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="sort" scope="col">
                                                        Loại thông báo
                                                    </th>
                                                    <th className="sort" scope="col">
                                                        Nội dung
                                                    </th>
                                                    <th scope="col">Hành động</th>
                                                </tr>
                                            </thead>
                                            {/* dữ liệu */}
                                            <Droppable droppableId="droppable-1">
                                                {(provider) => (
                                                    <tbody
                                                        className="list"
                                                        ref={provider.innerRef}
                                                        {...provider.droppableProps}
                                                    >
                                                        {dataSearch?.map((item, index) => (
                                                            <Draggable
                                                                key={item._id}
                                                                draggableId={item._id}
                                                                index={index}
                                                            >
                                                                {(provider) => (
                                                                    <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                                        <td scope="row">{item?.type ? DataTypeNotify.find(x => x.value === item?.type).label : ""}</td>
                                                                        <td>{removeTagHtml(item?.content)}</td>
                                                                        <td className="table-actions">
                                                                            <Button onClick={() => setIsOpen(item._id)}>
                                                                                <BsTrashFill className="text-warning" />
                                                                            </Button>
                                                                            <Button onClick={() => handleEditNotify(item)}>
                                                                                <BiSolidEdit className="text-warning" />
                                                                            </Button>
                                                                        </td>

                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provider.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </Table>
                                    </DragDropContext>
                                ) : (
                                    <p className="text-center">Không có dữ liệu</p>
                                )}
                            </div>
                        </Card>
                    </div>
                </Row>
            </Container>
            <CardFooter className="py-4">
                <nav aria-label="...">
                    <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                    >
                        <PaginationItem
                            className={pageIndex == "1" && "disabled"}
                        >
                            <PaginationLink
                                href="#pablo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPageIndex(pageIndex - 1);
                                }}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>
                        {renderPaginationItemDivs(
                            totalPage,
                            pageIndex,
                            setPageIndex
                        )}

                        <PaginationItem
                            className={pageIndex == totalPage && "disabled"}
                        >
                            <PaginationLink
                                href="#pablo"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPageIndex(pageIndex + 1);
                                }}
                                tabIndex="1"
                            >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </nav>
            </CardFooter>
            <Modal title="Delete" open={isOpen} onOk={handleDelete} onCancel={() => setIsOpen(false)}>
                <p>Bạn có chắc chắn muốn xóa?</p>
            </Modal>
            <ModalNotify
                title={title}
                dataUpdate={dataUpdate}
                isOpenModal={isOpenModal}
                handleCancelModal={handleCancelModal}
                handleGetAllNotify={handleGetAllNotify}
            />
        </>
    )
}

export default ManagementNotify;