const DataTypeNotify = [
    {
        value: 1,
        label: 'Thông báo bài viết đạt',
    },
    {
        value: 2,
        label: 'Thông báo bài viết không đạt',
    },
]
export default DataTypeNotify;