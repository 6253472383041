import { getInstance } from "helper/axios";
//get
export async function getAllNotifyContent() {
    const res = await getInstance().get(`notify/notify-content`);
    return res?.data;
}
//create
export async function createNotifyContent(data) {
    const res = await getInstance().post(`notify/notify-content`, data);
    return res?.data;
}
//update
export async function updateNotifyContent(id, data) {
    const res = await getInstance().put(`notify/notify-content/${id}`, data);
    return res?.data;
}
//remove
export async function deleteNotifyContent(id) {
    const res = await getInstance().delete(`notify/notify-content/${id}`);
    return res?.data;
}